@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind colors;

@font-face {
  font-family: "Nunito";
  src: url(./fonts/NunitoSans-ExtraLight.ttf);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(./fonts/NunitoSans-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(./fonts/NunitoSans-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(./fonts/NunitoSans-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(./fonts/NunitoSans-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(./fonts/NunitoSans-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(./fonts/NunitoSans-Black.ttf);
  font-weight: 900;
  font-style: normal;
}

/* Italic Nunito */

@font-face {
  font-family: "Nunito";
  src: url(./fonts/NunitoSans-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: url(./fonts/NunitoSans-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: url(./fonts/NunitoSans-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: url(./fonts/NunitoSans-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: url(./fonts/NunitoSans-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: url(./fonts/NunitoSans-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: url(./fonts/NunitoSans-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li[aria-current="page"] button {
  @apply bg-indigo-50;
}

aside > .bg-white {
  background: inherit !important;
}

/* Frequency Graph */

.graph-container .links line {
  stroke-opacity: 0.3;
}

.graph-container .links path {
  stroke-opacity: 0.3;
  fill: none;
}

.graph-container .nodes circle {
  fill-opacity: 0.3;
}

.graph-container text {
  font-family: sans-serif;
  text-anchor: middle;
  font-weight: 700;
}

/* Side bar menu */
.sidebar-icon svg {
  @apply h-6 w-6;
}

/* Wordcloud */
object.wordcloud text {
  font-family: Nunito;
  animation: word-explosion 1s ease-out;
}
